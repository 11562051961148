import { Button, Table } from "react-bootstrap";
import { Bundle } from "../../../api/interfaces/modules";
import { Image } from "react-bootstrap";

interface Props {
  selectedBundle: Bundle | null;
  activeBundle: Bundle | null;
  onCheckout: () => Promise<void>;
}

export function Basket({ selectedBundle, activeBundle, onCheckout }: Props) {
  const selectedBundles = selectedBundle ? [selectedBundle] : [];
  return (
    <>
      <Table className="basketTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Pricing</th>
          </tr>
        </thead>
        <tbody>
          {selectedBundles.map((bundle) => {
            return (
              <tr key={bundle.id}>
                <td >{bundle.name}</td>
                <td>{bundle.pricing}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        className="plausible-event-name=Button+Manage+Plan btn-primary"
        variant={selectedBundle && selectedBundle.id === activeBundle?.id ? "dark" : "primary"}
        onClick={onCheckout}
      >
        Manage Plan
      </Button>
      <div>
        <p>
          <Image src='/images/discount-offer.jpg' className="sidebarCTA" />
        </p>
      </div>
    </>
  );
}
