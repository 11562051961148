import styled from "styled-components";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { InfoTooltip } from "../InfoTooltip";
import { FavoriteIcon } from "./FavoriteIcon";
import { Link } from "react-router-dom";
import { IS_WEB_VERSION } from "../../utils";
import { ImageSlider } from "../layout/ImageSlider";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  listing: ListingCompactView;
  url?: string;
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: ${IS_WEB_VERSION ? "112px" : "90px"};
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: #fff;

  a {
    text-decoration: none;
  }
`;

const Address = styled.div`
  // color: #333333;
  font-weight: bold;
  font-size: ${IS_WEB_VERSION ? "20px" : "16px"};
  margin-top: 10px;
`;
const MiddleRow = styled.div`
  color: #000000;
  font-size: ${IS_WEB_VERSION ? "16px" : "12px"};

  span {
    float: right;
  }
`;

const ListingProps = styled.div`
  margin-top: 3px;
  font-size: ${IS_WEB_VERSION ? "16px" : "12px"};
  span {
    // color: #656f78;
    border-left: 1px solid #656f78;
    padding: 0px 5px;
  }
  span:first-child {
    padding-left: 0px;
    border: none;
  }
  span.last {
    float: right;
    padding: 0px;
    border: none;
  }
`;

const ImageWrapper = styled(Image)`
  width: ${IS_WEB_VERSION ? "156px" : "125px"};
  height: ${IS_WEB_VERSION ? "112px" : "90px"};
  object-fit: cover;
`;

const ActionBtn = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;

  button {
    border: none;
    background: none;
    color: #545452;
    font-size: 12px;
  }

  svg {
    color: #c1c1c1;
    max-height: 15px;
  }
`;

export function ListingCard({ listing, url, className }: Props) {
  const apiContext = useApi();
  const property = listing;
  const [favorite, setFavorite] = useState<boolean>(property.favorite);

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    await apiContext?.addToFavorites(listingKey);
    setFavorite(true);
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    await apiContext?.removeFromFavorites(listingKey);
    setFavorite(false);
  };

  const image = (
    <ImageWrapper
      src={
        property.media_url === ""
          ? "/images/not-found.jpeg"
          : property.media_url
      }
    />
  );

  const faces = property.direction_faces ? <span>Faces {property.direction_faces}</span> : null

  const occupancy = property.occupancy_type ? <span>{property.occupancy_type}</span> : null

  return (
    <>
      <Wrapper className={className}>
        <Container>
          <Row>
            <Col style={{ flex: "0 0 125px", paddingLeft: 0 }}>
              {IS_WEB_VERSION && <ImageSlider images={property.media?.map(x => x['MediaURL']) ?? [property.media_url]} />}
              {!IS_WEB_VERSION && (
                <>
                  {url && (
                    <Link target={"_blank"} to={url}>
                      {image}
                    </Link>
                  )}
                  {!url && (
                    <ImageSlider images={property.media?.map(x => x['MediaURL']) ?? [property.media_url]} />
                  )}
                </>
              )}
            </Col>
            <Col>
              <Link to={`/rentomatic/${property.listing_key}`}>
                <Address>{property.full_address}</Address>
                <MiddleRow>
                  {property.city}
                  {", "}
                  {property.state} {property.postal_code}
                  <span>
                    {property.list_price &&
                      !Number.isNaN(property.list_price) && (
                        <InfoTooltip
                          placement='left'
                          label='Last List Price'
                        >{`$${property.list_price.toLocaleString()}`}</InfoTooltip>
                      )}
                  </span>
                </MiddleRow>
                <ListingProps>
                  <span>{property.bedrooms_total || 0} bed</span>
                  <span>{property.bathrooms_total_decimal || 0} bath</span>
                  <span>
                    {property.living_area
                      ? property.living_area.toLocaleString()
                      : 0}{" "}
                    sqft
                  </span>
                  <span>{property.property_sub_type ?? "N/A"}</span>
                  {IS_WEB_VERSION ? <span>{`Built ${property.year_built}`}</span> : ""}
                  {IS_WEB_VERSION ? faces : ""}
                  {IS_WEB_VERSION ? occupancy : ""}
                  <span className='last'>
                    {property.standard_status}
                    {property.standard_status === "Closed" &&
                      property.close_date &&
                      `: ${new Date(property.close_date).toLocaleDateString(
                        "en-US"
                      )}`}
                  </span>
                </ListingProps>
              </Link>
            </Col>
          </Row>
        </Container>
        {property.listing_key !== "custom" && (
          <ActionBtn>
            <FavoriteIcon
              favorite={favorite}
              loading={false}
              onClick={() =>
                favorite
                  ? handleRemoveFromFavorites(property.listing_key)
                  : handleAddToFavorites(property.listing_key)
              }
            />
          </ActionBtn>
        )}
      </Wrapper>
    </>
  );
}
