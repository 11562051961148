import { Col, Row, Image, Container } from "react-bootstrap";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { styled } from "styled-components";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { IS_WEB_VERSION } from "../../utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  listing: ListingCompactView;
  selected?: boolean;
  listingAction?: React.ReactNode;
}

const ListingImage = styled(Image)`
  // width: 70px;
  // height: 50px;
  width: ${IS_WEB_VERSION ? "112px" : "70px"};
  height: ${IS_WEB_VERSION ? "80px" : "50px"};
  object-fit: cover;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const ActionBtn = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;

  button {
    border: none;
    background: none;
    color: #545452;
    font-size: 12px;
  }

  svg {
    color: #c1c1c1;
    max-height: 15px;
  }
`;

const ListingContainer = styled(Container)`
  border-bottom: 1px solid;
  // font-size: 12px;
  font-size: ${IS_WEB_VERSION ? "16px" : "12px"};
  // height: 80px;
  height: ${IS_WEB_VERSION ? "" : "80px"};
  padding: 15px 0px 0px 0px;
`;

const ListingProps = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;

  span {
    border-left: 1px solid #74777c;
    padding: 0px 5px;
  }
  span:first-child {
    padding-left: 0px;
    border: none;
  }
`;

const ListingName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  font-weight: bold;
`;

const ListingPrice = styled.div`
  span {
    padding-left: 25px;
    color: #3eaf3f;

    &.redPrice {
      color: #ff6058;
    }
  }
`;

const ListingCol = styled(Col)`
  width: 165px;
  padding-left: 0px;
`;

export function ListingPanel(props: Props) {
  const ref = useRef(null);
  const listing = props.listing;
  let priceDiff = 0;
  let sign = "+";
  if (listing.property_type === "Residential Lease" && listing.close_price) {
    priceDiff = listing.close_price - listing.list_price;
    if (priceDiff < 0) {
      priceDiff = Math.abs(priceDiff);
      sign = "-";
    }
  }

  let imageTitle = "";
  if (listing.close_date) {
    imageTitle = `MLS# ${listing.listing_id} closed on ${listing.close_date}`;
  }

  if (ref && ref.current !== null && props.selected) {
    // @ts-ignore
    ref.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  return (
    <>
      <ListingContainer
        ref={ref}
        className={
          `listingContainer ${props.selected ? "selected" : ""} ` +
          props.className
        }
      >
        <Row>
          <Col style={{ flex: "0 0 95px" }}>
            <Link to={`/rentomatic/${listing.listing_key}`}>
              <ListingImage
                title={imageTitle}
                src={
                  listing.media_url === ""
                    ? "/images/not-found.jpeg"
                    : listing.media_url
                }
              />
            </Link>
          </Col>
          <ListingCol>
            <div>
              <ListingPrice title='Last List Price'>
                ${listing.list_price.toLocaleString()}{" "}
                {priceDiff !== 0 && (
                  <span
                    className={sign === "-" ? "redPrice" : ""}
                    title='Difference between Close Price and List Price'
                  >
                    {sign}${priceDiff}
                  </span>
                )}
              </ListingPrice>
              <ListingName className='listingName'>
                {listing.full_address}
              </ListingName>
              <ListingProps className='listingProps'>
                <span>{listing.bedrooms_total || 0} bed</span>
                <span>{listing.bathrooms_total_decimal || 0} bath</span>
                <span>{listing.living_area.toLocaleString() || 0} sqft</span>
              </ListingProps>
            </div>
          </ListingCol>
        </Row>
      </ListingContainer>
      <ActionBtn>{props.listingAction}</ActionBtn>
    </>
  );
}
