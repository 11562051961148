import { Col, Row } from "react-bootstrap";
import { ArrowLeft } from "react-feather";

import styled from "styled-components";

const BottomErrorMsg = styled.div`
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  width: calc(100% - 24px);
`;

export const ErrorBox = () => {
  return (
    <BottomErrorMsg>
      <Row>
        <Col style={{ "flex": "0 0 20px", paddingRight: 0 }}>
          <ArrowLeft />
        </Col>
        <Col>
          Something not working? Share details using the "Feedback" link in menu bar.
        </Col>
      </Row>
    </BottomErrorMsg>
  );
};
