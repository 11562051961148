import { useDebounce } from "@uidotdev/usehooks";
import { ContentWithSideBar } from "../../components/layout";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";

import { CreamMain } from "./CreamMain";
import { CreamSidebar } from "./CreamSidebar";
import Pagination from "./components/Pagination";
import { useListing } from "../../hooks/useListing";
import { useCream } from "../../hooks/useCream";
import { CreamTypes } from "../../interfaces";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { useAuth } from "../../hooks/useAuth";

export const CreamWrapper = () => {
  const creamContext = useCream();
  const limitPerPage = 10;

  const [activeListingScore, setActiveListingScore] = useState<
    number | null | undefined
  >(null);

  const [minZoomRequired, setMinZoomRequired] = useState<boolean>(false)

  const apiContext = useApi();
  const authContext = useAuth();
  const listingContext = useListing();

  const debouncedFilters = useDebounce(creamContext?.state.filters, 500);
  const debouncedBbox = useDebounce(creamContext?.state.bbox, 1000);

  const resetResults = () => {
    creamContext?.setState({
      ...creamContext.state,
      total: 0,
      listings: [],
      activeListing: undefined,
    });
  };

  const fetchData = async (signal: AbortSignal) => {
    if (!creamContext) return;
    console.log(creamContext.state.bbox)
    if (creamContext.state.bbox.zoom_level < 11) {
     setMinZoomRequired(true)
     return
    }
    setMinZoomRequired(false)
    const data = await apiContext?.getListings(
      {
        property_type: "Residential",
        standard_status: "Active",
        ...creamContext.state.filters,
        ...creamContext.state.bbox,
      },
      creamContext?.state.offset,
      creamContext?.state.sortBy,
      limitPerPage,
      signal,
      true
    );
    const newState = {
      ...creamContext.state,
      total: data.count,
      listings: data.results,
    };
    if (
      !data.results.find(
        (l: ListingCompactView) =>
          l.listing_key === creamContext.state.activeListing?.listing_key
      )
    ) {
      newState["activeListing"] = undefined;
    }
    return newState;
  };

  useEffect(() => {
    if (!creamContext) return;
    
    const abortController = new AbortController();
    const signal = abortController.signal;
    resetResults();
    fetchData(signal)
      .then((newState) => {
        if (!signal.aborted && newState) {
          creamContext.setState(newState);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          resetResults();
        }
      })

    return () => {
      abortController.abort();
    };
  }, [
    debouncedFilters,
    debouncedBbox,
    creamContext?.state.offset,
    creamContext?.state.sortBy
  ]);

  const unsetActiveListing = () => {
    creamContext?.setState({
      ...creamContext.state,
      activeListing: undefined,
    });
    setActiveListingScore(undefined);
  };

  const loadListing = async (listingKey: string): Promise<void> => {
    if (listingKey === creamContext?.state.activeListing?.listing_key) {
      unsetActiveListing();
      return;
    }
    const creamScore = creamContext?.state.listings.find(
      (x) => listingKey === x.listing_key
    )?.estimation?.cream_score;
    const listing = await apiContext?.getListing(listingKey);
    creamContext?.setState({
      ...creamContext.state,
      activeListing: listing,
    });
    listingContext?.setListing(listing);
    listingContext?.setListingKey(listingKey);
    setActiveListingScore(creamScore);
  };

  if (creamContext === null) {
    return null;
  }

  return (
    <ContentWithSideBar
      fullscreen
      center={
        <>
          <CreamMain
            minZoomRequired={minZoomRequired}
            isLoading={apiContext?.searchEndpointLoading ?? false}
            creamUsageAccepted={creamContext.creamUsageAccepted}
            bbox={creamContext.state.bbox}
            activeListing={creamContext.state.activeListing}
            isAdmin={authContext?.user?.is_superuser ?? false}
            selectedSorting={creamContext.state.sortBy}
            listings={creamContext.creamUsageAccepted ? creamContext.state.listings : []}
            setCreamUsageAccepted={() =>
              creamContext.setCreamUsageAccepted(true)
            }
            onMapChange={(bbox: CreamTypes.BBox) =>
              creamContext.setState({
                ...creamContext.state,
                offset: 0,
                bbox,
              })
            }
            setListings={(listings) =>
              creamContext.setState({
                ...creamContext.state,
                listings,
              })
            }
            onSortingChange={(sortBy: string) =>
              creamContext.setState({
                ...creamContext.state,
                sortBy,
              })
            }
            loadListing={loadListing}
          />
          {creamContext.creamUsageAccepted && <Pagination
            total={creamContext.state.total}
            offset={creamContext.state.offset}
            setOffset={(offset: number) =>
              creamContext.setState({
                ...creamContext.state,
                offset,
              })
            }
            limit={limitPerPage}
          />}
        </>
      }
      sidebar={
        <CreamSidebar
          activeListing={creamContext.state.activeListing}
          activeListingScore={activeListingScore}
          filters={creamContext.state.filters}
          setFilters={(filters: CreamTypes.Filters) =>
            creamContext.setState({
              ...creamContext.state,
              filters,
            })
          }
        />
      }
    />
  );
};
