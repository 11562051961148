import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useApi } from "../../../api/useApi";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { ListingCard } from "../../components/listing";

export const SearchPage = () => {
  //@ts-ignore
  const { logCustomError, currentUrl } = useOutletContext();
  logCustomError("Search page not supported");

  const apiContext = useApi();
  const [listings, setListings] = useState<ListingCompactView[]>([]);
  const [portal, setPortal] = useState<string | null>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const getRandomIndex = (limit: number): number => {
    return Math.floor(Math.random() * limit)
  }

  const getUserPreferences = async () => {
    const data = await apiContext?.getUserPreferences();
    if (data) {
      setPortal(data.preferred_portal)
    }

  }

  const handleLoadRandomListings = async () => {
    const limit = 3
    const data = await apiContext?.getListings(
      {
        property_type: "Residential",
        standard_status: "Active",
        days_on_market_min: 7,
        days_on_market_max: 90,
        list_price_min: 600000,
        list_price_max: 1200000,
        property_subtype: ["Single Family Residence"],
        lot_size_area_max: 0.5,
      },
      0,
      null,
      limit
    );
    try {
      const index1 = getRandomIndex(data.results.length)
      const index2 = getRandomIndex(data.results.length)
      const index3 = getRandomIndex(data.results.length)

      setListings([
        data.results[index1],
        data.results[index2],
        data.results[index3],
      ]);
    } catch (error) { }
  };

  useEffect(() => {
    setInitialLoading(true);

    Promise.all([handleLoadRandomListings(), getUserPreferences()])
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []);

  const getPortalUrl = (listing: ListingCompactView): string | null => {
    const allUrls = [
      listing.external_url_zillow,
      listing.external_url_realtor,
      listing.external_url_redfin,
    ].filter((url) => url !== null);

    if (portal === 'ZILLOW') {
      return listing.external_url_zillow;
    }
    if (portal === 'REALTOR') {
      return listing.external_url_realtor;
    }
    if (portal === 'REDFIN') {
      return listing.external_url_redfin;
    }
    return allUrls.length > 0 ? allUrls[0] : null;
  };

  return (
    <>
      <p>
        Doh! Looks like you’re still searching for the right property. SELF works
        once you’re looking at a single listing.
      </p>

      {initialLoading && <LoadingIndicator />}
      <br /><br /><br />
      <div className="mt-3">
        {!initialLoading && <p>Try these Listings</p>}
        {listings.map((x) => (
          <div className='mb-3' key={x.listing_key}>
            {getPortalUrl(x) !== null && (
              <ListingCard className="withBoxShadow" listing={x} url={getPortalUrl(x)!} />
            )}
          </div>
        ))}
      </div>
    </>
  );
};
