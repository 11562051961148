import ReactPaginate from "react-paginate";

interface Props {
  total: number;
  offset: number;
  limit: number;
  setOffset: (offset: number) => void;
}

function PaginationContainer({ total, offset, limit, setOffset }: Props) {

  const itemOffset = offset
  const endOffset = itemOffset + limit;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const pageCount = Math.ceil(total / limit);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % total;
    setOffset(newOffset);
  };
  const currentPage = Number.isNaN(offset / limit) ? 0 : offset / limit
  return (
    <ReactPaginate
      className="pagination"
      breakLabel="..."
      nextLabel="Next >"
      onPageChange={handlePageClick}
      forcePage={currentPage}
      pageRangeDisplayed={4}
      pageCount={pageCount}
      previousLabel="< Previous"
      renderOnZeroPageCount={null}
      marginPagesDisplayed={2}
    />
  );
}

export default PaginationContainer;
