import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const Title = styled.div`
  color: #000;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 40px;
`;

const Content1 = styled.div`
  color: #000;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 0px 40px;
`;

const Content2 = styled.div`
  color: #000;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 10px 40px;
`;

const Footer = styled.div`
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 20px 0 10px;
`;

const Checkbox = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 20px 0 0 200px;

  input.form-check-input {
    border: 1px solid #000;
    border-radius: 0
  }
`;

interface Props {
  onAccept: () => void
  title: string;
  content1: string;
  content2: string;
  confirmLabel?: string;
}

export const ModalWindow = (props: Props) => {
  return (
    <Modal
      show
      onHide={props.onAccept}
      backdrop='static'
      keyboard={false}
    >
      <Title>{props.title}
      </Title>
      <Content1>{props.content1}
      </Content1>
      <Content2>{props.content2}
      </Content2>
      <Checkbox>
        <Form.Check type='checkbox' label={props.confirmLabel ?? 'I agree'} onChange={() => props.onAccept()} />
      </Checkbox>
      <Footer>Copyright © Self Realty, LLC</Footer>
    </Modal>
  );
};
