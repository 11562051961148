import { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../api/useApi";
import { ListingCompactView } from "../../api/interfaces/listing";

export interface Item {
  propertyId: string;
  address: string;
  source: string;
  status?: string;
}

const sleep = (interval: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, interval);
  });
};

const SearchWrapper = styled.div`
  max-width: 450px;
  min-width: 350px;

  .form-control {
    border-radius: 30px;
  }

  .dropdown-menu {
    background: #fff !important;
  }
  .dropdown-item,
  .dropdown-item:hover {
    color: #000 !important;
  }
`;
interface Props {
  value?: string | null;
  isLoading: boolean;
  setStatusMessage: (status: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  onChange: (propertyId: string, address: string) => void;
}

export const SearchTypeAhead = (props: Props) => {
  const { setStatusMessage, setIsLoading, isLoading } = props;
  const [options, setOptions] = useState<Item[]>([]);
  const navigate = useNavigate();


  const apiContext = useApi();
  const location = useLocation();

  const extractAddressFromUrl = async (query: string) => {
    setStatusMessage("Listing link received...");
    try {
      const response = await apiContext?.getAddressFromUrl(query);
      return response.address;
    } catch (error) {
      return null;
    }
  };

  const parseAddressString = async (query: string) => {
    setStatusMessage("Confirming the address...");
    try {
      return await apiContext?.parseAddressFromString(query);
    } catch (error) {
      return null;
    }
  };

  const searchForListingMatch = async (query: string) => {
    setStatusMessage("Checking database for listing...");
    try {
      const items = await apiContext?.searchMetadata(query);
      return items.map((item: any) => ({
        address: item.address,
        source: "CL",
        propertyId: item.v1PropertyId,
      }));
    } catch (error) {
      return [];
    }
  };

  const searchListingByAddress = async (
    searchQuery: object
  ): Promise<Item[]> => {
    setStatusMessage("Double checking the address...");
    try {
      const data = await apiContext?.getListings(
        {
          property_type: "Residential",
          ...searchQuery,
        },
        0,
        null,
        20
      );
      return data.results.map((listing: ListingCompactView) => ({
        address: `${listing.unparsed_address} [${listing.standard_status}]`,
        source: "MLS",
        propertyId: listing.listing_key,
        status: listing.standard_status
      }));
    } catch (error) {
      return [];
    }
  };

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    let options: Item[] = [];
    if (query.startsWith("http") || query.startsWith("www")) {
      const addressString = await extractAddressFromUrl(query);
      const address = await parseAddressString(addressString);
      if (address) {
        options = await searchListingByAddress(address);
      }
    } else {
      options = await searchForListingMatch(query);
    }
    if (options.length > 0) {
      setOptions(options);
    }
    setStatusMessage("");
    setIsLoading(false);
  };

  const handlePropertySelection = async (items: any[]) => {
    if (items.length > 0) {
      setIsLoading(true);
      if (items[0].source === "MLS") {
        props.onChange(items[0].propertyId, items[0].address);
      } else {
        const address = await parseAddressString(items[0].address);
        if (address) {
          const results = await searchListingByAddress(address);
          if (results.length > 0) {
            const sortedResults = results.sort((a, b) => a.status === "Closed" ? 1 : -1);
            props.onChange(sortedResults[0].propertyId, sortedResults[0].address);
          } else {
            navigate(`/lookup/${items[0].propertyId}`, {
              state: { address: items[0].address },
            });
          }
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.value) {
      handleSearch(props.value);
    }
  }, [props.value]);

  const filterBy = () => true;
  if (props.value === null) {
    return null;
  }

  return (
    <SearchWrapper>
      <AsyncTypeahead
        minLength={0}
        defaultInputValue={props.value}
        defaultOpen={
          props.value !== undefined && (location.pathname === "/search" || location.pathname === "/")
        }
        filterBy={filterBy}
        placeholder='Try entering an address or listing url'
        isLoading={false}
        id='typeAhead'
        //@ts-ignore
        labelKey={(option) => `${option.address}`}
        onSearch={handleSearch}
        onChange={handlePropertySelection}
        options={isLoading ? [] : options}
        emptyLabel={isLoading ? "Searching" : "No results"}
      />
    </SearchWrapper>
  );
};
