import React from "react";
import { Col, Row } from "react-bootstrap";
import { PageTitle } from "./PageTitle";
import { LoadingIndicator } from "../LoadingIndicator";

interface Props {
  center?: React.ReactNode;
  sidebar?: React.ReactNode;
  title?: React.ReactNode | string;
  sideBarTitle?: React.ReactNode | string;
  fullscreen?: boolean;
  loading?: boolean;
}

export const ContentWithSideBar = (props: Props) => {
  const mainColumnClassName = `mainCol withBoxShadow ${props.fullscreen ? "fullscreen" : ""}`
  if (props.loading) {
    return <Row><Col className={mainColumnClassName}><LoadingIndicator /></Col></Row>
  }
  return (
    <Row>
      <Col className={mainColumnClassName}>
        {props.title && <PageTitle>{props.title}</PageTitle>}
        {props.center}
      </Col>
      {props.sidebar && <Col className='sidebarCol'>
        {props.sideBarTitle && <PageTitle>{props.sideBarTitle}</PageTitle>}
        {props.sidebar}
      </Col>}
    </Row>
  );
};
