import { Col, Row } from "react-bootstrap";
import { Mail, MessageCircle } from "react-feather";

import { Listing } from "../../../api/interfaces/listing";
import { styled } from "styled-components";

interface Props {
  listing?: Listing;
}

const ContactDetailsRow = styled(Row)`
  margin-bottom: 5px;

  a,
  span {
    font-weight: 100;
    text-decoration: none;
  }
`;

export function ContactDetails({ listing }: Props) {
  if (!listing) {
    return (
      <ContactDetailsRow>
        <Col xs={12}>
          Not available. Use the contact form on the listing page.
        </Col>
      </ContactDetailsRow>
    );
  }

  return (
    <>
      <ContactDetailsRow>
        <Col xs={8}>
          <div>
            {listing?.list_agent_full_name &&
              `AGENT ${listing?.list_agent_full_name}`}
          </div>
          {listing?.list_agent_direct_phone && (
            <a href={`tel:${listing?.list_agent_direct_phone}`}>
              {listing?.list_agent_direct_phone}
            </a>
          )}
        </Col>
        <Col xs={4} className="alignRight">
          {listing?.list_agent_email && (
            <a
              href={"mailto:" + listing.list_agent_email}
              style={{ marginRight: 10 }}
            >
              <Mail />
            </a>
          )}
          {listing?.list_agent_direct_phone && (
            <a href={"sms:" + listing.list_agent_direct_phone}>
              <MessageCircle />
            </a>
          )}
        </Col>
      </ContactDetailsRow>
      {listing.list_office_name && (
        <ContactDetailsRow>
          <Col xs={12}>
            <div>BROKER {listing.list_office_name}</div>
            {listing?.list_office_phone && (
              <a href={`tel:${listing?.list_office_phone}`}>
                {listing?.list_office_phone}
              </a>
            )}
          </Col>
        </ContactDetailsRow>
      )}
      {listing.data_source !== "CORELOGIC" && (
        <ContactDetailsRow>
          <Col xs={12}>
            <div>SOURCE {listing.data_source}</div>
            <span>MLS#: {listing.listing_id}</span>
          </Col>
        </ContactDetailsRow>
      )}
      {listing.data_source === "CORELOGIC" && "N/A"}
    </>
  );
}
