import { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  User,
  LogOut,
  ArrowLeftCircle,
  ArrowRightCircle,
  Heart,
  Server,
  Sliders,
  Clock,
} from "react-feather";
import { useApi } from "../../../api/useApi";
import styled from "styled-components";
import { SubjectListing } from "../SubjectListing";
import { useAuth } from "../../hooks/useAuth";
import { IS_WEB_VERSION } from "../../utils";

const NavigationWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 900;
  position: absolute;
  border-bottom: ${IS_WEB_VERSION ? '2px solid #000' : '1px solid #000'};
`;

const NavigationButtons = styled.div`
  position: absolute;
  height: 40px;
  line-height: 40px;
  left: 90px;

  button {
    background: none;
    border: none;
    padding: 0;
  }
`;

const Logo = styled.div`
  height: 40px;
  width: 80px;
  position: absolute;
  text-align: center;
  border-bottom: ${IS_WEB_VERSION ? '2px solid #000' : '1px solid #000'};

  img {
    width: ${IS_WEB_VERSION ? '120px' : '60px'};
    padding-top: 6px;
    padding-left: ${IS_WEB_VERSION ? '10px' : '0px'};
  }
`;

export const Navigation = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [historyStack, setHistoryStack] = useState<string[]>([]);

  const context = useApi();
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        if (data) {
          setUser(
            `${data?.first_name} ${data?.last_name ? data?.last_name[0] : ""}`
          );
          authContext?.setUser(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  if (user === null) {
    return null;
  }
  const canGoBack =
    location.key !== "default" && location.pathname !== "/not_found";

  const canGoForward = historyStack.length > 0;

  const goBack = () => {
    navigate(-1);
    setHistoryStack([...historyStack, location.key]);
  };

  const goForward = () => {
    navigate(1);
    historyStack.pop();
    setHistoryStack([...historyStack]);
  };

  return (
    <NavigationWrapper>
      <Logo id='logo'>
        <a href='https://app.selfrealty.com/search'>
          <Image src='/images/horizontal-logo.png' />
        </a>
      </Logo>
      {!IS_WEB_VERSION && (
        <NavigationButtons>
          <button disabled={!canGoBack} onClick={() => goBack()}>
            <ArrowLeftCircle />
          </button>
          <button disabled={!canGoForward} onClick={() => goForward()}>
            <ArrowRightCircle />
          </button>
        </NavigationButtons>
      )}
      <SubjectListing />

      <div className='accountDropdown'>
        <Dropdown onSelect={(path) => navigate(path ?? "/")}>
          <Dropdown.Toggle>
            {user} <User />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey='/accounts/profile'>
              Account <User />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/favorites'>
              Favorites <Heart />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/dashboard'>
              Services <Server />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/accounts/preferences'>
              Preferences <Sliders />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/history'>
              History <Clock />
            </Dropdown.Item>
            <Dropdown.Item eventKey='/accounts/logout'>
              Logout <LogOut />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </NavigationWrapper>
  );
};
