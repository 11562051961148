import { Image } from "react-bootstrap";
import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const AgencyPage = () => {
  return <Wrapper>
    <Image width={300} src='/images/module_icons/agency-services.png' />
    <h1>Agency Services</h1>
    <p>On-demand, fee-based services from real estate professionals.</p>
    <h2>- Coming Soon -</h2>
  </Wrapper>
}