import * as Sentry from "@sentry/browser";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Menu from "./Menu";
import { Container } from "react-bootstrap";
import { styled } from "styled-components";
import { Navigation } from "./layout/Navigation";
import { useAuth } from "../hooks/useAuth";
import { IS_WEB_VERSION } from "../utils";
import { ModalWindow } from "./ModalWindow";
import { useModules } from "../hooks/useModules";

const View = styled.div`
  width: 100%;
  background: linear-gradient(146deg, #ffcf6f 73.46%, #fff 113.1%);
  
  padding: 45px 0 22px;
  font-size: 14px;
  color: #000;
  position: relative;
  padding-left: 88px;
  
  z-index: 200;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Sidebar = styled.div`
  z-index: 400;
  position: absolute;
  height: calc(100% - 40px);
  top: 40px;
  left: 0px;
  width: ${IS_WEB_VERSION ? '100px' : '80px'};;
  background: linear-gradient(175deg, #ffcf6f 78%, #fff 160.27%);

  display: flex;
  flex-direction: column;
  align-items: center;

  .menuBtn {
    width: ${IS_WEB_VERSION ? '72px' : '58px'};
    height: ${IS_WEB_VERSION ? '72px' : '58px'};
    color: #c8c8ec;
    font-size: 20px;
    background-color: #383d53;
    border: none;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    border: 2px solid transparent;
    box-sizing: content-box;

    &.moduleActive {
      border: 2px solid #ffb41d;
    }

    img {
      width: ${IS_WEB_VERSION ? '72px' : '58px'};
      height: ${IS_WEB_VERSION ? '72px' : '58px'};
      display: block;
    }
  }
`;


const getCurrentUrl = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "requestUrl" }, "*", [channel.port2]);
  });

const destroyIframe = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "destroy" }, "*", [channel.port2]);
  });

export function LayoutView() {
  const location = useLocation();
  const navigate = useNavigate();
  const modulesContext = useModules();
  const [searchParams] = useSearchParams();
  const state = location.state;
  const documentRef = useRef(document);
  const auth = useAuth();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [currentUrl, setCurrentUrl] = useState<null | string>(null);
  function logCustomError(err: string) {
    const captureContext = {
      tags: {
        url: currentUrl,
      },
    };
    Sentry.captureMessage(`${err} for ${currentUrl}`, captureContext);
  }

  const acceptDisclaimer = () => {
    setShowDisclaimer(false)
    const path = searchParams.get("input") ? `/search?input=${searchParams.get("input")}` : ".";
    navigate(path, { replace: true });
  }

  useEffect(() => {
    getCurrentUrl().then(setCurrentUrl);
  }, []);

  useEffect(() => {
    if (state?.diclaimer) {
      setShowDisclaimer(true);
    }
  }, [state]);


  useEffect(() => {
    if (modulesContext?.hasNoModules() === true && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard/checkout");
    }
  }, [modulesContext?.modules, showDisclaimer, location]);

  useEffect(() => {
    documentRef.current.addEventListener("click", (event: MouseEvent) => {
      const view = documentRef.current.getElementById("view");
      const logo = documentRef.current.getElementById("logo");
      const sidebar = documentRef.current.getElementById("sidebar");
      const isClickedInsideFrame = [
        // @ts-ignore
        view?.contains(event.target),
        // @ts-ignore
        logo?.contains(event.target),
        // @ts-ignore
        sidebar?.contains(event.target),
      ].some((v) => v === true);
      // @ts-ignore
      if (!isClickedInsideFrame && event.target?.tagName === "HTML") {
        destroyIframe();
      }
    });
  }, []);

  return (
    <>
      <View id='view'>
        <Sidebar id='sidebar'>
          <Menu />
        </Sidebar>
        <Container>
          {auth?.userToken && <Navigation />}
          <Outlet
            context={{
              currentUrl,
              logCustomError,
            }}
          />
          {showDisclaimer && <ModalWindow title="Welcome to Self Realty [beta]! 🚀" content1="This beta software best experienced on your desktop and some features may not work as intended. Please use the feedback link in the bottom left of your screen. Your participation is invaluable in helping us improve." content2="As a token of our appreciation, enjoy 25% off Self Realty Professional using code 'TEXAS25'—valid at checkout through January 30th! 🎉" confirmLabel="I Agree" onAccept={acceptDisclaimer} />}
        </Container>
      </View>
    </>
  );
}
