import { createRoot } from "react-dom/client";
import App from "./ui/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

import * as Sentry from "@sentry/react";
import { AuthProvider } from "./ui/hooks/useAuth";
import { ApiProvider } from "./api/useApi";
import { ListingProvider } from "./ui/hooks/useListing";
import { CreamProvider } from "./ui/hooks/useCream";
import { ModulesProvider } from "./ui/hooks/useModules";
import { IS_WEB_VERSION } from "./ui/utils";
import styled from "styled-components";

if (process.env.REACT_APP_DEBUG !== "true") {
  Sentry.init({
    dsn: "https://6bace72a505b1e7ba77476be04984c83@o4506855158513664.ingest.us.sentry.io/4506866216534016",
    tunnel: `${process.env.REACT_APP_API_URL}/tunnel/`,
  });
}

const ExtensionWrapper = styled.div`
  margin: 0 10px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 3.946px 10.522px 0px rgba(0, 0, 0, 0.5);
  height: calc(98vh);
`;

const Root = (
  <AuthProvider>
    <ApiProvider>
      <ModulesProvider>
        <ListingProvider>
          <CreamProvider>
            <App />
          </CreamProvider>
        </ListingProvider>
      </ModulesProvider>
    </ApiProvider>
  </AuthProvider>
);

if (
  (IS_WEB_VERSION && window === window.top) ||
  (!IS_WEB_VERSION && window !== window.top)
) {
  const root = createRoot(document.getElementById("root")!);
  root.render(
    !IS_WEB_VERSION ? <ExtensionWrapper>{Root}</ExtensionWrapper> : Root
  );
}
