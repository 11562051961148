import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export const ProtectedRoute = ({ children }: any) => {
  const context = useAuth();
  const location = window.location.href.replace(window.location.origin, '')
  if (!context || !context.userToken) {

    return <Navigate to="/accounts/login" state={{ from: location }} />;
  }
  return children;
};