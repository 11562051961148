import styled from "styled-components";
import { Image, ProgressBar } from "react-bootstrap";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../../api/useApi";
import { SearchTypeAhead } from "./SearchTypeAhead";
import { ErrorBox } from "./not_found";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Logo = styled.div`
  width: 200px;
  margin: 0px 0px;
  img {
    width: 100%;
  }
`;

const DivCenter = styled.div`
  width: 60%;
`;

export const SearchPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0)
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [searchParams] = useSearchParams();

  const viewProperty = (propertyId: string, address: string) => {
    navigate(`/rentomatic/${propertyId}`);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setProgress(progress + 1)
      }, 100)
    } else {
      setProgress(0)
    }
  }, [isLoading, progress])


  return (
    <Wrapper className='mt-3'>
      <Logo className='mb-3'>
        <Image src='/images/horizontal-logo.png' />
      </Logo>
      <SearchTypeAhead
        setIsLoading={setIsLoading}
        setStatusMessage={setStatusMessage}
        onChange={viewProperty}
        isLoading={isLoading}
        value={searchParams.get("input") ?? undefined}
      />
      {isLoading && (
        <DivCenter className='mt-5'>
          <ProgressBar animated now={progress} />
          <div className='alignCenter mt-2'>{statusMessage}</div>
        </DivCenter>
      )}
      <ErrorBox />
    </Wrapper>
    
  );
};
