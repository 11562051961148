import { Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { CTABtn } from "./CTABtn";
import { useModules } from "../../hooks/useModules";

const Wrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  color: #000;
`;

const ImageCol = styled(Col)`
  flex: 0 0 85px;
`;

const BtnCol = styled(Col)`
  align-content: space-evenly;
`;

const Preview = styled.div`
  font-size: 12px;
  line-height: 12px;  
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  listingKey?: string | null;
  estimatedReturn?: number;
}

export const DossierWidget = (props: Props) => {
  const modulesContext = useModules()

  if (!props.listingKey) return null
  const enabled = modulesContext?.hasDossier() ?? false
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image rounded src='/images/module_icons/dossier.png' width={85} />
        </ImageCol>
        <BtnCol>
          <Preview className='mb-2'>
            Confirm ownership, listing history, and more...
          </Preview>
          <CTABtn enabled={enabled} url="/dossier" title="GET DOCUMENTS" className="plausible-event-name=CTA+Next+Steps+Dossier" />
        </BtnCol>
      </Row>
    </Wrapper>
  );
};
