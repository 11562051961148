import { useEffect, useState } from "react";

import { useApi } from "../../../api/useApi";
import { Proforma as ProformaType } from "../../../api/interfaces/proforma";

import { useNavigate, useParams } from "react-router-dom";
import hash from "object-hash";
import { Proforma } from "./Proforma";
import { useListing } from "../../hooks/useListing";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import { ContentWithSideBar } from "../../components/layout";
import { useModules } from "../../hooks/useModules";

export const ProformaWrapper = () => {
  const { listingString } = useParams();
  const apiContext = useApi();
  const modulesContext= useModules()
  const listingContext = useListing();
  const navigate = useNavigate();
  const [proforma, setProforma] = useState<ProformaType | null>(null);
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [hashKey, setHash] = useState<string>("");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);


  const handleLoadListing = async (listingKey: string): Promise<void> => {
    const data = await apiContext?.getListing(listingKey);
    if (data) {
      listingContext?.setListing(data);
      listingContext?.setListingKey(data.listing_key);
    }
    await handleLoadProforma(data)
  };

  const handleLoadProforma = async (listing: Listing): Promise<void> => {
    try {
      const proforma = await apiContext?.getProforma(listing);
      if (proforma) {
        setProforma(proforma);
      }
    } catch (error) {
      // @ts-ignore
      if (error.status === 403)(
        navigate('/dashboard')
      )
    }
    const rentomatic = await apiContext?.getRentomatic(listing, []);
    if (rentomatic) {
      setComparables(rentomatic.comparables);
    }
  };

  const onSave = async (proforma: ProformaType): Promise<void> => {
    // setInitialLoading(true);
    // const newProforma = await apiContext?.saveProforma(listingKey!, proforma);
    // if (newProforma) {
    //   setProforma(newProforma);
    // }
    // setInitialLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    // setInitialLoading(true);
    // await apiContext?.deleteProforma(listingKey!);
    // // await handleLoadProforma();
    // setInitialLoading(false);
  };

  const onReset = () => {
    setProforma(proforma);
    setHash(
      hash({
        time: new Date().valueOf(),
      })
    );
  };

  useEffect(() => {
    if (listingContext?.listing && !listingString) {
      setInitialLoading(true);
      handleLoadProforma(listingContext.listing)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else if (!listingString) {
      setInitialLoading(false);
    }
  }, [listingContext?.listing]);
  
  useEffect(() => {
    if (listingString) {
      setInitialLoading(true);
      handleLoadListing(listingString).finally(() => {
        setInitialLoading(false);
      });
    } else if (!listingContext?.listing){
      setInitialLoading(false);
    }
  }, [listingString]);


  if (initialLoading || modulesContext?.loading) {
    return <ContentWithSideBar loading />;
  }

  if (!modulesContext?.hasProformanator()) {
    navigate(`/dossier`);
    return null;
  }

  if (!listingContext?.listing && !listingString) {
    navigate("/not_found");
    return null;
  }

  return (
    <>
      <Proforma
        key={hashKey}
        proforma={proforma!}
        comparables={comparables}
        listing={listingContext?.listing!}
        onSave={onSave}
        onReset={onReset}
        onDelete={onDelete}
      />
    </>
  );
};
