import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { Bundle } from "../../../api/interfaces/modules";
import { ContentWithSideBar } from "../../components/layout";
import { Checkout } from "./Checkout";
import { Basket } from "./Basket";
import { useNavigate } from "react-router-dom";
import { useModules } from "../../hooks/useModules";

export function CheckoutWrapper() {
  const apiContext = useApi();
  const modulesContext = useModules();
  const navigate = useNavigate();
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeBundle, setActiveBundle] = useState<Bundle | null>(null);
  const [selectedBundle, setSelectedBundle] = useState<Bundle | null>(null);

  const refreshBundles = async () => {
    const res = await apiContext?.getModulesBundles()
    setBundles(res);
  };
  
  useEffect(() => {
    const newActiveBundle = bundles.find((bundle) => bundle.active) ?? null
    if (activeBundle?.id !== newActiveBundle?.id) {
      setSelectedBundle(newActiveBundle);
    }
    setActiveBundle(newActiveBundle);
  }, [bundles])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await refreshBundles();
      setLoading(false);
    }
    fetchData()
  }, []);

  useEffect(() => {
    const interval1 = setInterval(() => refreshBundles(), 5000);
    const interval2 = setInterval(() => modulesContext?.getUserModules(), 5000);
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, []);

  const onBundleSelect = (bundle: Bundle, selected: boolean): void => {
    if (selected) {
      setSelectedBundle(bundle);
    } else {
      setSelectedBundle(null);
    }
  };

  const manageSubscription = async () => {
    const response = await apiContext?.manageSubscription();
    window.open(response.url, "_blank");
  };

  const onCheckout = async (): Promise<void> => {
    if (selectedBundle) {
      try {
        const response = await apiContext?.manageSubscription();
        window.open(response.url, "_blank");
      } catch (error) {
        navigate("/dashboard/subscribe", {
          state: { productsIds: [selectedBundle.price_id] },
        });
      }
    }
  };

  const addCredits = async (): Promise<void> => {
    navigate("/dashboard/subscribe", {
      state: { productsIds: [process.env.REACT_APP_STRIPE_CREDITS_PRICE_ID] },
    });
  };

  if (loading) {
    return <ContentWithSideBar loading={true}  />;
  }
  
  return (
    <ContentWithSideBar
      title='BILLING'
      sideBarTitle='CART'
      center={
        <Checkout
          activeBundle={activeBundle}
          selectedBundle={selectedBundle}
          bundles={bundles}
          onSelect={onBundleSelect}
          addCredits={addCredits}
        />
      }
      sidebar={
        <Basket
          selectedBundle={selectedBundle}
          activeBundle={activeBundle}
          onCheckout={onCheckout}
        />
      }
    />
  );
}
